<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">Total Population Demand Capacity</h1>
      </div>

      <div class="validation-cards--item cert no-hover">
        <span class="card-header">Certification Status</span>
        <div class="card-icon" margin-top="0px">
          <img
            src="@/assets/img/circular-label-with-certified-stamp.png"
            alt=""
          />
          <span class="card-total">100% Validation</span>
        </div>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">Qasim</span>
          <!-- button class="btn btn-primary btn-outline mr-4 mb-4" :class="year1To4Table ? 'active' : ''" @click="addTable">Add Table</button -->
          <!-- button class="btn btn-primary btn-outline mr-4 mb-4" :class="year1To4Table ? 'active' : ''" @click="displayTable">Display Table</button -->
        </div>
      </div>
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Total Population</span>
          <span class="box-label"> {{ this.population }}</span>
        </div>
      </div>
    </div>

    <nav class="route-nav">
      <ul>
        <li
          @click="editStageSummary('stage')"
          :class="stageSummary == 'stage' ? 'active' : ''"
        >
          <span>Level 1</span>
        </li>
        <li
          @click="editStageSummary('carbfoot')"
          :class="stageSummary == 'carbfoot' ? 'active' : ''"
        >
        <span>Carbon Footprint</span>
        </li>
        <li
          @click="editStageSummary('specialities')"
          :class="stageSummary == 'specialities' ? 'active' : ''"
        >
          <span>Specialities</span>
        </li>
        <li
          @click="editStageSummary('buildingsFM')"
          :class="stageSummary == 'buildingsFM' ? 'active' : ''"
        >
          <span>Buildings and FM</span>
        </li>
        <li
          @click="editStageSummary('facman')"
          :class="stageSummary == 'facman' ? 'active' : ''"
        >
          <span>Facilities Management</span>
        </li>
        <li
          @click="editStageSummary('opImprov')"
          :class="stageSummary == 'opImprov' ? 'active' : ''"
        >
          <span>Operational Improvement</span>
        </li> 
        <li
          @click="editStageSummary('metrics')"
          :class="stageSummary == 'metrics' ? 'active' : ''"
        >
          <span>Population Demand Capacity Metrics</span>
        </li>              
      </ul>
    </nav>

    <div v-if="stageSummary == 'stage'">
      <div class="route-content">
        <div class="stat-cards" v-if="specialityData !== null">
          <div
            class="stat-cards--item single"
            :class="activeSummary === 'patientEpisode' ? 'active' : ''"
            @click="fillData('patientEpisode')"
          >
            <span class="card-header">Patient Episode Productivity</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-patient.svg"
                alt="Patient Episodes"
              />

              <!-- {{ patientEpisodeProductivityFilter1 | numeral }} -->
              <span class="card-total"> {{ getTileData(0) }}</span>
            </div>
            <span class="card-info">Total patient episodes</span>
          </div>

          <div
            class="stat-cards--item single"
            :class="activeSummary === 'assets' ? 'active' : ''"
            @click="fillData('assets')"
          >
            <span class="card-header">Assets</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <span class="card-total"> {{ getTileData(1) }}</span>
            </div>
            <span class="card-info">Total Assets</span>
          </div>

          <div
            class="stat-cards--item single"
            :class="activeSummary === 'workforceCost' ? 'active' : ''"
            @click="fillData('workforceCost')"
          >
            <span class="card-header">Workforce</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <span class="card-total"> {{ getTileData(2) }}</span>
            </div>
            <span class="card-info"
              >Total Workforce Full Time Equivalent (FTE)</span
            >
          </div>

          <div
            class="stat-cards--item single"
            :class="activeSummary === 'finance' ? 'active' : ''"
            @click="fillData('finance')"
          >
            <span class="card-header">Finance</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <span class="card-total"> {{ getTileData(3) }}</span>
            </div>
            <span class="card-info">Budget Requirement</span>
          </div>

          <div class="stat-cards--item single no-hover">
            <span class="card-header">Workforce Cost per Capita</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="SupplyChain"
              />
              <span class="card-total"> {{ getTileData(4) }}</span>
            </div>
          </div>

          <div
            class="stat-cards--item single no-hover"
            :class="activeSummary === 'assets' ? 'active' : ''"
            @click="fillData('assets')"
          >
            <span class="card-header">Total Health Episodes per Capita</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />
              <span class="card-total"> {{ getTileData(5) }}</span>
            </div>
            <span class="card-info"></span>
          </div>

          <div
            class="stat-cards--item single"
            :class="activeSummary === 'workforceCost' ? 'active' : ''"
            @click="fillData('workforceCost')"
          >
            <span class="card-header">Total Workforce Cost</span>
            <div class="card-icon">
              <img
                src="@/assets/img/icons/icon-workforce.svg"
                alt="Workforce"
              />
              <span class="card-total">{{ getTileData(6) }}</span>
            </div>
            <span class="card-info"></span>
          </div>

          <div
            class="stat-cards--item single"
            :class="activeSummary === 'finance' ? 'active' : ''"
            @click="fillData('finance')"
          >
            <span class="card-header">Beneficiary Capitation Rate</span>
            <div class="card-icon">
              <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />
              <span class="card-total"> {{ getTileData(7) }}</span>
            </div>
            <span class="card-info"></span>
          </div>

          <div v-if="activeSummary == 'patientEpisode'">
            <!-- 1 open -->
            <div
              class="stat-cards--item single1 no-hover"
              :class="activeSummary === 'patientEpisode' ? 'active' : ''"
              @click="fillData('patientEpisode')"
            >
              <!-- 2 open -->
              <span class="card-header">Patient Episodes Summary</span>
              <div class="card-icon">
                <!-- 3 open -->
                <img
                  src="@/assets/img/icons/icon-patient.svg"
                  alt="Workforce"
                />

                <div class="flex flex-col">
                  <!-- 4 open -->
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <!-- 5 open -->
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                      <!-- 6 open -->
                      <div class="overflow-hidden">
                        <!-- 7 open-->
                        <table class="min-w-full">
                          <thead class="border-b">
                            <tr>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                              >
                                Patient Episode
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                Quantity
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="border-b">
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-left"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in summaryPatientFilter"
                                  :key="index"
                                >
                                  <!-- 8 open-->
                                  {{ workforce }}
                                </div>
                                <!-- 8 close -->
                              </td>

                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in summaryPatientQuantityFilter"
                                  :key="index"
                                >
                                  <!-- 9 open -->
                                  {{ workforce }}
                                </div>
                                <!-- 9 close -->
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                              >
                                Total
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                {{ summaryPatientQuantityFilterSum }}
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- 7 close-->
                    </div>
                    <!-- 6 close-->
                  </div>
                  <!-- 5 close -->
                </div>
                <!-- 4 close -->
              </div>
              <!-- 3 close -->
              <span class="card-info"></span>
            </div>
            <!-- 2 close -->
          </div>
          <!-- 1 close -->

          <div v-if="activeSummary == 'patientEpisode'">
            <!-- 1 open -->
            <div class="chartcontainersummaryone">
              <div class="title_text1">PATIENT EPISODES</div>
              <Bar
                :chart-options="chartOptions"
                :chart-data="datacollection"
                :chart-id="chartId"
                :dataset-id-key="datasetIdKey"
                :plugins="plugins"
                :css-classes="cssClasses"
                :styles="styles"
                :width="100"
                :height="65"
              />
            </div>
          </div>

          <div v-if="activeSummary == 'patientEpisode'">
            <!-- 1 open -->
            <div class="stat-cards--item single2 no-hover">
              <!-- 2 open -->
              <span class="card-header">Patient Episodes</span>
              <div class="card-icon">
                <!-- 3 open -->
                <div class="flex flex-col">
                  <!-- 4 open -->
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <!-- 5 open -->
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                      <!-- 6 open -->
                      <div class="overflow-hidden">
                        <!-- 7 open -->
                        <table class="min-w-full">
                          <thead class="border-b">
                            <tr>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                              >
                                Patient Episode
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                Quantity
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="border-b">
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in specialityPatientEpisodesFilter1"
                                  :key="index"
                                >
                                  <!-- 8 open -->
                                  {{ workforce }}
                                </div>
                                <!-- 8 close-->
                              </td>
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in specialityPatientEpisodesTotalFilter1"
                                  :key="index"
                                >
                                  <!-- 9 open-->
                                  {{ workforce }}
                                </div>
                                <!-- 9 close -->
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                              >
                                Total
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                {{ specialityPatientEpisodesTotalFilter1Sum }}
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- 7 close -->
                    </div>
                    <!--6 close -->
                  </div>
                  <!--5 close -->
                </div>
                <!--4 close -->
              </div>
              <!--3 close-->
              <span class="card-info"></span>
            </div>
            <!-- 2 close -->
          </div>
          <!-- 1 close -->

          <div v-if="activeSummary == 'finance'">
            <!-- 1 open -->
            <div
              class="stat-cards--item single1 no-hover" style="width:600px"
              :class="activeSummary === 'finance' ? 'active' : ''"
              @click="fillData('finance')"
            >
              <!-- 2 open -->
              <span class="card-header">Finance Summary</span>
              <div class="card-icon">
                <!-- 3 open -->
                <img src="@/assets/img/icons/icon-finance.svg" alt="Finance" />

                <div class="flex flex-col">
                  <!-- 4 open -->
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <!-- 5 open -->
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                      <!-- 6 open -->
                      <div class="overflow-hidden">
                        <!-- 7 open-->
                        <table class="min-w-full">
                          <thead class="border-b">
                            <tr>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                              >
                                Finance
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                Finance Total
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="border-b">
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-left"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in specialityFinanceNameFilter1"
                                  :key="index"
                                >
                                  <!-- 8 open-->
                                  {{ workforce }}
                                </div>
                                <!-- 8 close -->
                              </td>

                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in specialityFinanceTotalFilter1"
                                  :key="index"
                                >
                                  <!-- 9 open -->
                                  {{ workforce }}
                                </div>
                                <!-- 9 close -->
                              </td>
                            </tr>
                            <!-- tr>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                              >
                                Total
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                {{ specialityFinanceTotalFilter1Sum }}
                              </th>
                            </tr -->
                          </tbody>
                        </table>
                      </div>
                      <!-- 7 close-->
                    </div>
                    <!-- 6 close-->
                  </div>
                  <!-- 5 close -->
                </div>
                <!-- 4 close -->
              </div>
              <!-- 3 close -->
              <span class="card-info"></span>
            </div>
            <!-- 2 close -->
          </div>

          <div v-if="activeSummary == 'assets'">
            <!-- 1 open -->
            <div
              class="stat-cards--item single1 no-hover"
              :class="activeSummary === 'assets' ? 'active' : ''"
              @click="fillData('assets')"
            >
              <!-- 2 open -->
              <span class="card-header">Asset Summary</span>
              <div class="card-icon">
                <!-- 3 open -->
                <img src="@/assets/img/icons/icon-graph.svg" alt="Assets" />

                <div class="flex flex-col">
                  <!-- 4 open -->
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <!-- 5 open -->
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                      <!-- 6 open -->
                      <div class="overflow-hidden">
                        <!-- 7 open-->
                        <table class="min-w-full">
                          <thead class="border-b">
                            <tr>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                              >
                                Assets
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                Quantity
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                Total Capital Cost
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="border-b">
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-left"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in summaryAssetFilter"
                                  :key="index"
                                >
                                  <!-- 8 open-->
                                  {{ workforce }}
                                </div>
                                <!-- 8 close -->
                              </td>

                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in summaryAssetQuantityFilter"
                                  :key="index"
                                >
                                  <!-- 9 open -->
                                  {{ workforce }}
                                </div>
                                <!-- 9 close -->
                              </td>

                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in summaryAssetTotalCapitalCostFilter"
                                  :key="index"
                                >
                                  <!-- 10 open-->
                                  {{ workforce }}
                                </div>
                                <!-- 10 close -->
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                              >
                                Total
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                {{ summaryAssetQuantityFilterSum }}
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                {{ summaryAssetTotalCapitalCostFilterSum }}
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- 7 close-->
                    </div>
                    <!-- 6 close-->
                  </div>
                  <!-- 5 close -->
                </div>
                <!-- 4 close -->
              </div>
              <!-- 3 close -->
              <span class="card-info"></span>
            </div>
            <!-- 2 close -->
          </div>
          <!-- 1 close -->

          <div v-if="activeSummary == 'assets'">
            <!-- 1 open -->
            <div class="chartcontainersummaryone">
              <div class="title_text1">ASSETS</div>
              <Bar
                :chart-options="chartOptions"
                :chart-data="datacollection"
                :chart-id="chartId"
                :dataset-id-key="datasetIdKey"
                :plugins="plugins"
                :css-classes="cssClasses"
                :styles="styles"
                :width="100"
                :height="65"
              />
            </div>
          </div>

          <div v-if="activeSummary == 'assets'">
            <!-- 1 open -->
            <div class="stat-cards--item single2 no-hover">
              <!-- 2 open -->
              <span class="card-header">Assets</span>
              <div class="card-icon">
                <!-- 3 open -->
                <div class="flex flex-col">
                  <!-- 4 open -->
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <!-- 5 open -->
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                      <!-- 6 open -->
                      <div class="overflow-hidden">
                        <!-- 7 open -->
                        <table class="min-w-full">
                          <thead class="border-b">
                            <tr>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-800 px-6 py-4 text-left"
                              >
                                Assets
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-800 px-6 py-4 text-right"
                              >
                                Quantity
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-800 px-6 py-4 text-right"
                              >
                                Capital Cost Per Item
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-800 px-6 py-4 text-right"
                              >
                                Total Capital Cost
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-800 px-6 py-4 text-right"
                              >
                                Life Cycle
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-800 px-6 py-4 text-right"
                              >
                                Annual Cost
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-800 px-6 py-4 text-right"
                              >
                                Annual Maintenance Cost
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="border-b">
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-left"
                              >
                                <div
                                  v-for="(workforce, index) in assetsFilter1"
                                  :key="index"
                                >
                                  <!-- 8 open -->
                                  {{ workforce }}
                                </div>
                                <!-- 8 close-->
                              </td>
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in assetsQuantityFilter1"
                                  :key="index"
                                >
                                  <!-- 9 open-->
                                  {{ workforce }}
                                </div>
                                <!-- 9 close -->
                              </td>
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in assetsCapitalCostPerItemFilter1"
                                  :key="index"
                                >
                                  <!-- 10 open -->
                                  {{ workforce }}
                                </div>
                                <!-- 10 close -->
                              </td>
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in assetsTotalCapitalCostFilter1"
                                  :key="index"
                                >
                                  <!-- 11 open-->
                                  {{ workforce }}
                                </div>
                                <!-- 11 close -->
                              </td>
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in assetsLifeCycleFilter1"
                                  :key="index"
                                >
                                  <!-- 11 open-->
                                  {{ workforce }}
                                </div>
                                <!-- 11 close -->
                              </td>
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in assetsAnnualisedCostFilter1"
                                  :key="index"
                                >
                                  <!-- 11 open-->
                                  {{ workforce }}
                                </div>
                                <!-- 11 close -->
                              </td>
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-wrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in assetsAnnualisedMaintenanceCostFilter1"
                                  :key="index"
                                >
                                  <!-- 11 open-->
                                  {{ workforce }}
                                </div>
                                <!-- 11 close -->
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                              >
                                Total
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                {{ assetsQuantityFilter1Sum }}
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                {{ assetsCapitalCostPerItemFilter1Sum }}
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                {{ assetsTotalCapitalCostFilter1Sum }}
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                {{ assetsLifeCycleFilter1Sum }}
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                {{ assetsAnnualisedCostFilter1Sum }}
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                {{ assetsAnnualisedMaintenanceCostFilter1Sum }}
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- 7 close -->
                    </div>
                    <!--6 close -->
                  </div>
                  <!--5 close -->
                </div>
                <!--4 close -->
              </div>
              <!--3 close-->
              <span class="card-info"></span>
            </div>
            <!-- 2 close -->
          </div>
          <!-- 1 close -->

          <div v-if="activeSummary == 'workforceCost'">
            <!-- 1 open -->
            <div
              class="stat-cards--item single1 no-hover"
              :class="activeSummary === 'workforceCost' ? 'active' : ''"
              @click="fillData('workforceCost')"
            >
              <!-- 2 open -->
              <span class="card-header">Workforce</span>
              <div class="card-icon">
                <!-- 3 open -->
                <img
                  src="@/assets/img/icons/icon-workforce.svg"
                  alt="Workforce"
                />

                <div class="flex flex-col">
                  <!-- 4 open -->
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <!-- 5 open -->
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                      <!-- 6 open -->
                      <div class="overflow-hidden">
                        <!-- 7 open-->
                        <table class="min-w-full">
                          <thead class="border-b">
                            <tr>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                              >
                                Workforce
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                Quantity
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                Total Cost
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="border-b">
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-left"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in workforceTableWorkforceFilter"
                                  :key="index"
                                >
                                  <!-- 8 open-->
                                  {{ workforce }}
                                </div>
                                <!-- 8 close -->
                              </td>

                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in workforceTableQuantityFilter"
                                  :key="index"
                                >
                                  <!-- 9 open -->
                                  {{ workforce }}
                                </div>
                                <!-- 9 close -->
                              </td>

                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in summaryWorkforceTotalCostFilter"
                                  :key="index"
                                >
                                  <!-- 10 open-->
                                  {{ workforce }}
                                </div>
                                <!-- 10 close -->
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                              >
                                Total
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                <!-- 14,369 -->
                                {{ summaryWorkforceQuantitySum }}
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                <!-- 594,397,759 -->
                                {{ summaryWorkforceTotalCostSum }}
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- 7 close-->
                    </div>
                    <!-- 6 close-->
                  </div>
                  <!-- 5 close -->
                </div>
                <!-- 4 close -->
              </div>
              <!-- 3 close -->
              <span class="card-info"></span>
            </div>
            <!-- 2 close -->
          </div>
          <!-- 1 close -->

          <div v-if="activeSummary == 'workforceCost'">
            <!-- 1 open -->
            <div class="chartcontainersummaryone">
              <div class="title_text1">WORKFORCE</div>
              <Bar
                :chart-options="chartOptions"
                :chart-data="datacollection"
                :chart-id="chartId"
                :dataset-id-key="datasetIdKey"
                :plugins="plugins"
                :css-classes="cssClasses"
                :styles="styles"
                :width="100"
                :height="65"
              />
            </div>
          </div>

          <div v-if="activeSummary == 'workforceCost'">
            <!-- 1 open -->
            <!-- div class="stat-cards--item single2.width:44rem no-hover" -->
            <div class="stat-cards--item workforce_summary no-hover">
              <!-- 2 open -->
              <span class="card-header">Workforce Summary</span>
              <div class="card-icon">
                <!-- 3 open -->
                <div class="flex flex-col">
                  <!-- 4 open -->
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <!-- 5 open -->
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                      <!-- 6 open -->
                      <div class="overflow-hidden">
                        <!-- 7 open -->
                        <table class="min-w-full">
                          <thead class="border-b">
                            <tr>
                              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left">Workforce</th>
                              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-right">Quantity</th>
                              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-right">Staff Cost</th>
                              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-right">
                                Total Workforce Cost
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="border-b">
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in workforceSummaryTableWorkforceFilter"
                                  :key="index"
                                >
                                  <!-- 8 open -->
                                  {{ workforce }}
                                </div>
                                <!-- 8 close-->
                              </td>
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in workforceSummaryTableQuantityFilter"
                                  :key="index"
                                >
                                  <!-- 9 open-->
                                  {{ workforce }}
                                </div>
                                <!-- 9 close -->
                              </td>
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in workforceSummaryTableCostPerStaffFilter"
                                  :key="index"
                                >
                                  <!-- 10 open -->
                                  {{ workforce }}
                                </div>
                                <!-- 10 close -->
                              </td>
                              <td
                                class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-right"
                              >
                                <div
                                  v-for="(
                                    workforce, index
                                  ) in workforceSummaryTableTotalWorkforceCostFilter"
                                  :key="index"
                                >
                                  <!-- 11 open-->
                                  {{ workforce }}
                                </div>
                                <!-- 11 close -->
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                              >
                                Total
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                {{ workforceSummaryTableQuantityFilterSum }}
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                {{ workforceSummaryTableCostPerStaffFilterSum }}
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-right"
                              >
                                {{
                                  workforceSummaryTableTotalWorkforceCostFilterSum
                                }}
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- 7 close -->
                    </div>
                    <!--6 close -->
                  </div>
                  <!--5 close -->
                </div>
                <!--4 close -->
              </div>
              <!--3 close-->
              <span class="card-info"></span>
            </div>
            <!-- 2 close -->
          </div>
          <!-- 1 close -->
        </div>
      </div>
    </div>

    <div v-if="stageSummary == 'specialities'">
      <div class="route-content-speciality">
        <div class="route-speciality items-center secondary">
          <div class="route-speciality--box">
            <div class="box">
              <span class="box-header">Speciality</span>
              <span class="box-label">
                <v-select
                  v-model="selectedSpeciality"
                  label="speciality"
                  :clearable="false"
                  @input="fetchData"
                  :options="$store.state.prototypedata.specialities"
                />
              </span>
            </div>
          </div>

          <div class="route-speciality--box">
            <div class="box">
              <span class="box-header">Speciality Area</span>
              <span class="box-label">
                <v-select
                  v-model="selectedSpecialityArea"
                  label="label"
                  :clearable="false"
                  :options="$store.state.prototypedata.dataTypes1"
                />
              </span>
            </div>
          </div>
        </div>

        <!-- now check to see if any of the specialities areas have changed -->
        <div v-if="specialityType == 'tactical'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Tactical Assumptions</th>
                    <th class="border px-4 py-2"></th>
                    <th class="border px-4 py-2"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          patientEpisodes, index
                        ) in tacticalAssumptionsFilter"
                        :key="index"
                      >
                      <span v-if="patientEpisodes == ''"><div v-html="message"/></span>
                      <span v-else-if="patientEpisodes == '-'"><div v-html="message"/></span>
                      <span v-else> {{  patientEpisodes }}</span> 
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          patientEpisodesQuantity, index
                        ) in tacticalAssumptionsTypeFilter"
                        :key="index"
                      >
                      <span v-if="patientEpisodesQuantity == ''"><div v-html="message"/></span>
                      <span v-else-if="patientEpisodesQuantity == '-'"><div v-html="message"/></span>
                      <span v-else> {{  patientEpisodesQuantity }}</span> 
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          patientEpisodesQuantity, index
                        ) in tacticalAssumptionsQuantityFilter"
                        :key="index"
                      >
                      <span v-if="patientEpisodesQuantity == ''"><div v-html="message"/></span>
                      <span v-else-if="patientEpisodesQuantity == '-'"><div v-html="message"/></span>
                      <span v-else> {{  patientEpisodesQuantity }}</span> 
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

           <!-- now check to see if any of the specialities areas have changed -->
           <div v-if="specialityType == 'patientEpisodes'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Patient Episodes</th>
                    <th class="border px-4 py-2">Patient Episodes Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          patientEpisodes, index
                        ) in specialityPatientEpisodesFilter"
                        :key="index"
                      >                      
                        <span v-if="patientEpisodes == ''"><div v-html="message"/></span>
                        <span v-else-if="patientEpisodes == '-'"><div v-html="message"/></span>
                        <span v-else> {{ patientEpisodes  }}</span> 
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          patientEpisodesQuantity, index
                        ) in specialityPatientEpisodesTotalFilter"
                        :key="index"
                      >                      
                        <span v-if="patientEpisodesQuantity == ''"><div v-html="message"/></span> <!-- numeral -->
                        <span v-else-if="patientEpisodesQuantity == '-'"><div v-html="message"/></span>
                        <span v-else> {{ patientEpisodesQuantity  }}</span> 
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div v-if="specialityType == 'finance'">
          <div class="route-content">
            <div v-if="specialityData !== null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Finance</th>
                    <th class="border px-4 py-2">Finance Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          patientEpisodes, index
                        ) in specialityFinanceNameFilter"
                        :key="index"
                      >                        
                        <span v-if="patientEpisodes == ''"><div v-html="message"/></span>
                        <span v-else-if="patientEpisodes == '-'"><div v-html="message"/></span>
                        <span v-else> {{ patientEpisodes  }}</span> 
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          financeName, index
                        ) in specialityFinanceTotalFilter"
                        :key="index"
                      >                        
                        <span v-if="financeName == ''"><div v-html="message"/></span> <!-- numeral -->
                        <span v-else-if="financeName == '-'"><div v-html="message"/></span>
                        <span v-else> {{ financeName  }}</span> 
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'space'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary" v-if="specialityData !== null">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Space & Property</th>
                    <th class="border px-4 py-2">Quantity</th>
                    <th class="border px-4 py-2">Capital Cost Per Item</th>
                    <th class="border px-4 py-2">Total Capital Cost</th>
                    <th class="border px-4 py-2">Life Cycle</th>
                    <th class="border px-4 py-2">Annualised Cost</th>
                    <th class="border px-4 py-2">
                      Annualised Maintenance Cost
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          spaceAndProperty, index
                        ) in specialitySpaceAndPropertyFilter"
                        :key="index"
                      >                        
                        <span v-if="spaceAndProperty == ''"><div v-html="message"/></span>
                        <span v-else-if="spaceAndProperty == '-'"><div v-html="message"/></span>
                        <span v-else> {{ spaceAndProperty  }}</span> 
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          spaceAndPropertyQuantity, index
                        ) in specialitySpaceAndPropertyQuantityFilter"
                        :key="index"
                      >                      
                        <span v-if="spaceAndPropertyQuantity == ''"><div v-html="message"/></span> <!-- numeral -->
                        <span v-else-if="spaceAndPropertyQuantity == '-'"><div v-html="message"/></span>
                        <span v-else> {{ spaceAndPropertyQuantity  }}</span> 
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          capitalCostPerItem, index
                        ) in specialityCapitalCostPerItemFilter"
                        :key="index"
                      >                      
                        <span v-if="capitalCostPerItem == ''"><div v-html="message"/></span> <!-- numeral-->
                        <span v-else-if="capitalCostPerItem == '-'"><div v-html="message"/></span>
                        <span v-else> {{ capitalCostPerItem  }}</span> 
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          totalCapitalCost, index
                        ) in specialityTotalCapitalCostFilter"
                        :key="index"
                      >                      
                        <span v-if="totalCapitalCost == ''"><div v-html="message"/></span> <!-- numeral -->
                        <span v-else-if="totalCapitalCost == '-'"><div v-html="message"/></span>
                        <span v-else> {{ totalCapitalCost  }}</span> 
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(lifeCycle, index) in specialityLifeCycleFilter"
                        :key="index"
                      >                    
                        <span v-if="lifeCycle == ''"><div v-html="message"/></span>
                        <span v-else-if="lifeCycle == '-'"><div v-html="message"/></span>
                        <span v-else> {{ lifeCycle  }}</span> 
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          annualisedCost, index
                        ) in specialityAnnualisedCostFilter"
                        :key="index"
                      >                        
                        <span v-if="annualisedCost == ''"><div v-html="message"/></span> <!-- numeral-->
                        <span v-else-if="annualisedCost == '-'"><div v-html="message"/></span>
                        <span v-else> {{ annualisedCost  }}</span> 
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          annualisedMaintenanceCost, index
                        ) in specialityAnnualisedMaintenanceCostFilter"
                        :key="index"
                      >                       
                        <span v-if="annualisedMaintenanceCost == ''"><div v-html="message"/></span> <!-- numeral -->
                        <span v-else-if="annualisedMaintenanceCost == '-'"><div v-html="message"/></span>
                        <span v-else> {{ annualisedMaintenanceCost  }}</span> 
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-if="specialityType == 'workforce'">
          <div class="route-content">
            <div v-if="specialityData != null">
              <table class="table-primary">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Workforce</th>
                    <th class="border px-4 py-2">Workforce Quantity</th>
                    <th class="border px-4 py-2">Cost Per Staff</th>
                    <th class="border px-4 py-2">Total Workforce Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <div
                        v-for="(
                          workforceName, index
                        ) in specialityWorkforceNameFilter"
                        :key="index"
                      >                  
                        <span v-if="workforceName == ''"><div v-html="message"/></span>
                        <span v-else-if="workforceName == '-'"><div v-html="message"/></span>
                        <span v-else> {{ workforceName  }}</span> 
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          financeName, index
                        ) in specialityWorkforceQuantityFilter"
                        :key="index"
                      >                      
                        <span v-if="financeName == ''"><div v-html="message"/></span> <!-- numeral -->
                        <span v-else-if="financeName == '-'"><div v-html="message"/></span>
                        <span v-else> {{ financeName  }}</span> 
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          workforceCostPerStaff, index
                        ) in specialityWorkforceCostPerStaffFilter"
                        :key="index"
                      >                        
                        <span v-if="workforceCostPerStaff == ''"><div v-html="message"/></span> <!-- numeral -->
                        <span v-else-if="workforceCostPerStaff == '-'"><div v-html="message"/></span>
                        <span v-else> {{ workforceCostPerStaff  }}</span> 
                      </div>
                    </td>

                    <td class="border">
                      <div
                        v-for="(
                          workforceTotalCost, index
                        ) in specialityWorkforceTotalWorkforceCostFilter"
                        :key="index"
                      >                
                        <span v-if="workforceTotalCost == ''"><div v-html="message"/></span> <!-- numeral -->
                        <span v-else-if="workforceTotalCost == '-'"><div v-html="message"/></span>
                        <span v-else> {{ workforceTotalCost  }}</span> 
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="stageSummary == 'opImprov'">
      <div class="route-content-speciality">
        <div class="route-speciality items-center secondary">
          

          <div class="route-speciality--box">
            <div class="box">
              <span class="box-header">Improvement Area</span>
              <span class="box-label">
                <v-select
                  v-model="improvementSpecialityArea"
                  label="label"
                  :clearable="false"
                  :options="$store.state.prototypedata.dataTypes2"
                />
              </span>
            </div>
          </div>
        </div>

        <!-- now check to see if any of the specialities areas have changed -->
        <div v-if="improvementType == 'diagnostics'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 1</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Planning Action Process</td>
                    <td class="border" style="padding: 10px;">7</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">planning action processs<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Total Annual Capacity</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Total Annual Capacity<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Total Annual Demand</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Total Annual Demand<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Identify capacity deficits and excesses</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Identify capacity deficits and excesses<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish total Demand and Capacity balanced model</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish total Demand and Capacity balanced model<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm current waiting list by specialty</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm current waiting list by specialty<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Diagnostics Target Operating Model by Specialty and Modality</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Diagnostics Target Operating Model by Specialty and Modality<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Monthly, Weekly and Daily Capacity output requirement by specialty and Modality</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Monthly, Weekly and Daily Capacity output requirement by specialty and Modality<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                </tbody>
              </table>
            <!-- /div -->
          </div>
        </div>

           <!-- now check to see if any of the specialities areas have changed -->
           <div v-if="improvementType == 'OPD'">
            <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 1</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Planning Action Process</td>
                    <td class="border" style="padding: 10px;">7</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">planning action processs<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Total Annual Capacity</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Total Annual Capacity<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Total Annual Demand</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Total Annual Demand<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Identify capacity deficits and excesses</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Identify capacity deficits and excesses<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish total Demand and Capacity balanced model</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish total Demand and Capacity balanced model<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm current waiting list by specialty</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm current waiting list by specialty<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm OPD Target Operating Model by Specialty</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm OPD Target Operating Model by Specialty<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Monthly, Weekly and Daily Capacity output requirement by specialty in OPD</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Monthly, Weekly and Daily Capacity output requirement by specialty in OPD<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                </tbody>
              </table>
            <!-- /div -->
          </div>
        </div>


        <div v-if="improvementType == 'OR'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 1</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Planning Action Process</td>
                    <td class="border" style="padding: 10px;">7</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">planning action processs<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Total Annual Capacity</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Total Annual Capacity<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Total Annual Demand</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Total Annual Demand<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Identify capacity deficits and excesses</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Identify capacity deficits and excesses<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish total Demand and Capacity balanced model</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish total Demand and Capacity balanced model<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm current waiting list by specialty</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm current waiting list by specialty<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm OR Target Operating Model by Specialty</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm OR Target Operating Model by Specialty<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Monthly, Weekly and Daily Capacity output requirement by specialty in OR</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Monthly, Weekly and Daily Capacity output requirement by specialty in OR<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                </tbody>
              </table>
            <!-- /div -->
          </div>
        </div>

        <div v-if="improvementType == 'ER'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 1</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Planning Action Process</td>
                    <td class="border" style="padding: 10px;">7</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">planning action processs<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Total Annual Capacity</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Total Annual Capacity<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Total Annual Demand</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Total Annual Demand<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Identify capacity deficits and excesses</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Identify capacity deficits and excesses<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish total Demand and Capacity balanced model</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish total Demand and Capacity balanced model<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Identify capacity for Minors, Majors, Paediatrics and Resuscitation</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Identify capacity for Minors, Majors, Paediatrics and Resuscitation<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm daily operating model by peak times</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm daily operating model by peak times<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Monthly, Weekly and Daily Capacity output requirement</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Monthly, Weekly and Daily Capacity output requirement<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                </tbody>
              </table>
            <!-- /div -->
          </div>
        </div>

        <div v-if="improvementType == 'bedflow'">
          <div class="route-content">
            <!-- div v-if="specialityData != null" -->
              <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Stage 1</th>
                    <th class="border px-4 py-2">Timeline</th>
                    <th class="border px-4 py-2">Teaching/Coaching Module</th>
                    <th class="border px-4 py-2">Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">Planning Action Process</td>
                    <td class="border" style="padding: 10px;">7</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">planning action processs<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Total Annual Capacity</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Total Annual Capacity<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish Total Annual Demand</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish Total Annual Demand<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Identify capacity deficits and excesses</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Identify capacity deficits and excesses<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Establish total Demand and Capacity balanced model</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Establish total Demand and Capacity balanced model<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Bed Type requirement by Specialty</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Bed Type requirement by Specialty<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm daily operating model</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm daily operating model<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                  <tr>
                    <td class="border" style="padding: 10px;">Confirm Monthly, Weekly and Daily Capacity output requirement</td>
                    <td class="border" style="padding: 10px;">5</td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">Confirm Monthly, Weekly and Daily Capacity output requirement<div style="height:100%;width:100%"></div></a></td>
                    <td class="border" style="padding: 10px;"><a href="https://www.healthcare-insight.com">link<div style="height:100%;width:100%"></div></a></td>
                  </tr>
                </tbody>
              </table>
            <!-- /div -->
          </div>  
        </div>
      </div>
    </div>

    <div v-if="stageSummary == 'metrics'">
      <div class="route-content">
        <div v-if="specialityData != null">
          <!-- 1 open -->
          <table class="table-primary">
            <tbody>
              <tr>
                <td class="border">
                  <div v-for="(jobPlan, index) in metricActivityFilter" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanPatients, index) in metricBeneficiaryFilter" :key="index">
                    <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanPatients }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanperSessions, index) in metricMetricsFilter" :key="index">
                    <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanperSessions }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanannSessions, index) in metricHSIBenchmarkFilter" :key="index">
                    <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanannSessions }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanweekSessions, index) in metricInternationalBenchmarkFilter" :key="index">
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanweekSessions }}</span> 
                  </div>
                </td>                                          
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div v-if="stageSummary == 'buildingsFM'">
      <div class="route-content">
        <div v-if="specialityData != null">
          <!-- 1 open -->
          <table class="table-primary">
            <thead>
              <tr>
                <th class="border px-4 py-2">Area</th>
                <th class="border px-4 py-2">Total Floor Space</th>
                <th class="border px-4 py-2">Full Development Cost</th>
                <th class="border px-4 py-2">Net Build Cost</th>
                <th class="border px-4 py-2">Avg FM Cost</th>
                <th class="border px-4 py-2">FM Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">
                  <div v-for="(jobPlan, index) in areaAreaFilter" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanPatients, index) in areaTFSFilter"
                    :key="index"
                  >
                    <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanPatients }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanSessions, index) in areaNBCFilter"
                    :key="index"
                  >
                    <span v-if="jobPlanSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanSessions }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanannSessions, index) in areaFDCFilter"
                    :key="index"
                  >
                    <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanannSessions }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in areaAFMCostFilter"
                    :key="index"
                  >
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanweekSessions }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in areaFMCostFilter"
                    :key="index"
                  >
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanweekSessions }}</span> 
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div v-if="stageSummary == 'carbfoot'">
      <div class="route-content">
        <div v-if="specialityData != null">
          <!-- 1 open -->          
           <table class="table-primary">         
            <thead>
              <tr>
                <th class="border px-4 py-2">Carbon Footprint</th>
                <th class="border px-4 py-2">Volume</th>
                <th class="border px-4 py-2"></th>
                <th class="border px-4 py-2"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">
                  <div v-for="(jobPlan, index) in carbFilter" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanPatients, index) in carbVol"
                    :key="index"
                  >
                    <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanPatients }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanperSessions, index) in carbEpisodes"
                    :key="index"
                  >
                    <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanperSessions }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanannSessions, index) in carbTotVol"
                    :key="index"
                  >
                    <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanannSessions }}</span> 
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          </div>              
      </div>
    </div>

     <div v-if="stageSummary == 'facman'">
      <div class="route-content">
        <div v-if="specialityData != null">
          <!-- 1 open -->          
           <table class="table-primary">         
            <thead>
              <tr>
                <th class="border px-4 py-2"></th>
                <th class="border px-4 py-2">Patient Catering</th>
                <th class="border px-4 py-2">Cleaning</th>
                <th class="border px-4 py-2">Reception</th>
                <th class="border px-4 py-2">Portering</th>
                <th class="border px-4 py-2">Security & Car Parking</th>
                <th class="border px-4 py-2">Waste</th>
                <th class="border px-4 py-2">HVAC</th>
                <th class="border px-4 py-2">Utilities</th>
                <th class="border px-4 py-2">Energy</th>
                <th class="border px-4 py-2">M&E</th>
                <th class="border px-4 py-2">Buildings and Estates</th>
                <th class="border px-4 py-2">Capital and Minor Projects</th>
                <th class="border px-4 py-2">Total</th>
                <th class="border px-4 py-2">Management</th>
                <th class="border px-4 py-2">Supervisors</th>
                <th class="border px-4 py-2">Administration</th>                
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border">
                  <div v-for="(jobPlan, index) in fmFilter" :key="index">                    
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                        <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                        <span v-else> {{ jobPlan  }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanPatients, index) in fmPCFilter"
                    :key="index"
                  >                  
                    <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
                        <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
                        <span v-else> {{ jobPlanPatients  }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanperSessions, index) in fmCleaningFilter"
                    :key="index"
                  >                  
                    <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
                        <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
                        <span v-else> {{ jobPlanperSessions  }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanannSessions, index) in fmReceptionFilter"
                    :key="index"
                  >                  
                    <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
                        <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
                        <span v-else> {{ jobPlanannSessions  }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in fmPorteringFilter"
                    :key="index"
                  >
                  
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                        <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                        <span v-else> {{ jobPlanweekSessions  }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in fmSecCarParkFilter"
                    :key="index"
                  >            
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                        <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                        <span v-else> {{ jobPlanweekSessions  }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in fmWasteFilter"
                    :key="index"
                  >                  
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                        <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                        <span v-else> {{ jobPlanweekSessions  }}</span>                         
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in fmhvacFilter"
                    :key="index"
                  >              
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                        <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                        <span v-else> {{ jobPlanweekSessions  }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in fmUtilitiesFilter"
                    :key="index"
                  >                  
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                        <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                        <span v-else> {{ jobPlanweekSessions  }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in fmEnergyFilter"
                    :key="index"
                  >                  
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                        <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                        <span v-else> {{ jobPlanweekSessions  }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in fmMEFilter"
                    :key="index"
                  >                  
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{ jobPlanweekSessions  }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in fmBuildingEstatesFilter"
                    :key="index"
                  >              
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanweekSessions }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in fmCapAndMinorFilter"
                    :key="index"
                  >                  
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                        <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                        <span v-else> {{ jobPlanweekSessions  }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in fmTotalFilter"
                    :key="index"
                  >                
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                        <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                        <span v-else> {{ jobPlanweekSessions  }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in fmManagementFilter"
                    :key="index"
                  >                  
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                        <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                        <span v-else> {{ jobPlanweekSessions  }}</span> 
                  </div>
                </td>

                   <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in fmSupervisorsFilter"
                    :key="index"
                  >                  
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                        <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                        <span v-else> {{ jobPlanweekSessions  }}</span> 
                  </div>
                </td>

                   <td class="border">
                  <div
                    v-for="(jobPlanweekSessions, index) in fmAdministrationFilter"
                    :key="index"
                  >                  
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                        <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                        <span v-else> {{ jobPlanweekSessions  }}</span> 
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          </div>              
      </div>
    </div>

    <div v-if="stageSummary == 'workforce'">
      <div class="route-content">
        <h1 class="text-3xl my-6 active">Workforce</h1>
        <table class="table-primary" v-if="specialityData !== null">
          <thead>
            <tr>
              <th class="border px-4 py-2">Workforce</th>
              <th class="border px-4 py-2">Workforce Quantity</th>
              <th class="border px-4 py-2">Cost Per Staff</th>
              <th class="border px-4 py-2">Total Workforce Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border">
                <div
                  v-for="(
                    workforceName, index
                  ) in specialityWorkforceNameFilter"
                  :key="index"
                >              
                  <span v-if="workforceName == ''"><div v-html="message"/></span>
                        <span v-else-if="workforceName == '-'"><div v-html="message"/></span>
                        <span v-else> {{ workforceName  }}</span> 
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(
                    financeName, index
                  ) in specialityWorkforceQuantityFilter"
                  :key="index"
                >                
                  <span v-if="financeName == ''"><div v-html="message"/></span> <!-- numeral -->
                        <span v-else-if="financeName == '-'"><div v-html="message"/></span>
                        <span v-else> {{ financeName  }}</span> 
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(
                    workforceCostPerStaff, index
                  ) in specialityWorkforceCostPerStaffFilter"
                  :key="index"
                >                
                  <span v-if="workforceCostPerStaff == ''"><div v-html="message"/></span> <!-- numeral -->
                        <span v-else-if="workforceCostPerStaff == '-'"><div v-html="message"/></span>
                        <span v-else> {{  workforceCostPerStaff }}</span> 
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(
                    workforceTotalCost, index
                  ) in specialityWorkforceTotalWorkforceCostFilter"
                  :key="index"
                >          
                  <span v-if="workforceTotalCost == ''"><div v-html="message"/></span> <!-- numeral -->
                        <span v-else-if="workforceTotalCost == '-'"><div v-html="message"/></span>
                        <span v-else> {{ workforceTotalCost  }}</span> 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { Bar } from "vue-chartjs/legacy";
//import { Bubble } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "bar",
  components: {
    Bar,
    //Bubble,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stageSummary: "stage",
      selectedSpeciality: "AHPs",
      apiPayload: { speciality: "", apiString: "" },
      activeSummary: "",
      population: "",
      selectedSpecialityArea: "Patient Episodes",
      improvementSpecialityArea: "Diagnostics",
      specialityType: "Patient Episodes",
      improvementType: "Diagnostics",
      specialityApi: "grandsummaryone-collection",
      firstUpdate: false,
      message: "<BR />",
      dataTable: {},
      dataSets: 0,
      rows: 0,
      cols : 0
    };
  },
  updated() {
    if (this.firstUpdate == false) {
      console.log("summarymain: updated");
      this.fillData("workforceCost");
      this.population = this.getPopulation();     
      this.firstUpdate = true;
    }
  },
  beforeMount() {
    console.log("summarymain: before mounted");
    this.apiPayload.apiString = "grandsummaryone-collection";
    this.getMongoDBData();
    this.fillData("workforceCost");
  },
  mounted() {
    console.log("summarymain: mounted");
    this.activeSummary = "workforceCost";
    this.apiPayload.speciality = "Grand Summary";
    this.apiPayload.apiString = "grandsummaryone-collection";

    this.getMongoDBData();
    this.fillData("workforceCost");
  },
  watch: {
    selectedSpecialityArea: {
      handler: function (val) {
        console.log(
          "selectedSpecialityArea: Value Change: ",
          val.label,
          val.string
        );
        this.editSpecialityType(val.label);
        //console.log("speciality: ", this.specialityApi);
        this.apiPayload.apiString = this.specialityApi;
        this.getMongoDBData();
      },
      deep: true,
    },
    improvementSpecialityArea: {
      handler: function (val) {
        console.log(
          "improvementArea: Value Change: ",
          val.label,
          val.string
        );
        this.editImprovementType(val.label);
        //console.log("speciality: ", this.specialityApi);
        //this.apiPayload.apiString = this.specialityApi;
        //this.getMongoDBData();
      },
      deep: true,
    },
    activeSummary: {
      handler: function (newvalue) {
        console.log("The new activeSummary value is ", newvalue);
        //this.fillData(newvalue);
        //console.log(
        //  "specialityOverviewFilter",
        //  this.specialityOverviewFilter()
        //);
      },
      deep: true,
    },
  },
  computed: {
    store() {
      return this.$store.state.prototypedata;
    },
    specialityName() {
      return this.$store.state.prototypedata.specialityName;
    },
    specialityData() {
      //console.log(
        //"specialityData: ",
        //this.$store.state.prototypedata.specialityData.length
      //);
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return true;
      }
      return null;
    },
    getAreaIndex() {
      var i = 0;
      var index = 0;

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "total_floor_space" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },
     getFMIndex() {
      var i = 0;
      var index = 0;

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "hvac" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getWorkforceIndex() {
      var i = 0;
      var index = 0;

      //console.log("begin getWorkforceIndex");

      if (this.$store.state.prototypedata.specialityData.length > 0) {
        for (
          i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          if (
            "workforce" in this.$store.state.prototypedata.specialityData[i]
          ) {
            index = i;
          }
        }
      }

      //console.log("end getWorkforceIndex");

      return index;
    },    
    getCarbIndex() {
      var i = 0;
      var index = 0;

      //console.log("begin getWorkforceIndex");

      if (this.$store.state.prototypedata.specialityData.length > 0) {
        for (
          i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          if (
            "carb_foot" in this.$store.state.prototypedata.specialityData[i]
          ) {
            index = i;
          }
        }
      }

      //console.log("end getWorkforceIndex");

      return index;
    },   
    getTacticalIndex() 
    {
      var i = 0;
      var index = -1;

      if (this.$store.state.prototypedata.specialityData.length > 0) {
        for (
          i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          if (
            "tactical_assumptions_type" in this.$store.state.prototypedata.specialityData[i]
          ) {
            index = i;
          }
        }
      }

      //console.log("end getWorkforceIndex");

      return index;
    },
    getPatientIndex() {
      var i = 0;
      var index = 0;

      //console.log("begin getPatientIndex");

      if (this.$store.state.prototypedata.specialityData.length > 0) {
        for (
          i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          if (
            "patient_episodes" in
            this.$store.state.prototypedata.specialityData[i]
          ) {
            index = i;
          }
        }
      }

      //console.log("end getPatientIndex");

      return index;
    },
    getSpaceIndex() {
      var i = 0;
      var index = 0;

      //console.log("getSpaceIndex");

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "space_and_property" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getPropertyIndex() {
      var i = 0;
      var index = -1;

      //console.log("getSpaceIndex");

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if (
          "fm_cost" in
          this.$store.state.prototypedata.specialityData[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    getFinanceIndex() {
      var i = 0;
      var index = 0;

      //console.log("getFinanceIndex");

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if ("finance" in this.$store.state.prototypedata.specialityData[i]) {
          index = i;
        }
      }

      return index;
    },    

    getPopulationIndex() {
      var i = 0;
      var index = -1;

      //console.log("getFinanceIndex");

      for (
        i = 0;
        i < this.$store.state.prototypedata.specialityData.length;
        i++
      ) {
        if ("population" in this.$store.state.prototypedata.specialityData[i]) {
          index = i;
        }
      }

      return index;
    },
   
    tacticalAssumptionsFilter() 
    {
      var i = this.getTacticalIndex;

      console.log ("getTacticalIndex : ", i);
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getTacticalIndex
        ].tactical_assumptions;
      }
      return null;
    },
    tacticalAssumptionsTypeFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getTacticalIndex
        ].tactical_assumptions_type;
      }
      return null;
    },
    tacticalAssumptionsQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getTacticalIndex
        ].tactical_assumptions_quantity;
      }
      return null;
    },
    WorkforceCostPerCapita() {

      let data = new Array ();
      let newData = new Array ();
      let tempData = new Array ();

      var str = "";

      var fm_cost = "";

      // first get the array
      if (this.$store.state.prototypedata.specialityData.length > 0) 
      {
        data.push (this.$store.state.prototypedata.specialityData[this.getDataSetIndex("workforce_cost_per_capita")].workforce_cost_per_capita[0]);

        for (var i = 0; i < data.length; i++) {
          str =  data[i].replaceAll(",", "");
          //console.log ("str: ", str);
          tempData.push(str);
        }

        //console.log ("fm_cost: ", tempData);

  

        for (i = 0; i< tempData.length; i++)
        {
          str = tempData[i];
          //console.log (str);
          if (this.isNumeric (str))
          {
            //console.log ("numeric: ", str);
            newData.push (str);
          }
        }

        //console.log ("newData", newData);

        if (newData.length > 0)  fm_cost = this.numberWithCommas(this.calculateArray (newData));

        //console.log ("fm_cost:", fm_cost);
      }
      return fm_cost;
    },
    TotalHealthEpisodesPerCapita() {

let data = new Array ();
let newData = new Array ();
let tempData = new Array ();

var str = "";

var fm_cost = "";

// first get the array
if (this.$store.state.prototypedata.specialityData.length > 0) 
{
  data.push (this.$store.state.prototypedata.specialityData[this.getDataSetIndex("workforce_cost_per_capita")].total_health_episodes_per_capita[0]);

  for (var i = 0; i < data.length; i++) {
    str =  data[i].replaceAll(",", "");
    //console.log ("str: ", str);
    tempData.push(str);
  }

  //console.log ("fm_cost: ", tempData);



  for (i = 0; i< tempData.length; i++)
  {
    str = tempData[i];
    //console.log (str);
    if (this.isNumeric (str))
    {
      //console.log ("numeric: ", str);
      newData.push (str);
    }
  }

  //console.log ("newData", newData);

  if (newData.length > 0)  fm_cost = this.numberWithCommas(this.calculateArray (newData));

  //console.log ("fm_cost:", fm_cost);
}
return fm_cost;
},
    specialityWorkforceCostPerStaffFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex
        ].cost_per_staff;
      }
      return null;
    },
    specialityWorkforceTotalWorkforceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex
        ].total_workforce_cost;
      }
      return null;
    },
    // Workforce Summary Table -- Workforce column
    specialityWorkforceNameFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex
        ].workforce;
      }
      return null;
    },
    // Workforce Table -- Quantity column
    specialityWorkforceQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getWorkforceIndex
        ].workforce_quantity;
      }
      return null;
    },
    specialitySpaceAndPropertyFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].space_and_property;
      }
      return null;
    },
    specialitySpaceAndPropertyQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].space_and_property_quantity;
      }
      return null;
    },
    specialityCapitalCostPerItemFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].capital_cost_per_item;
      }
      return null;
    },
    specialityTotalCapitalCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].total_capital_cost;
      }
      return null;
    },
    specialityLifeCycleFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].life_cycle;
      }
      return null;
    },
    specialityAnnualisedCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].annualised_cost;
      }
      return null;
    },
    specialityAnnualisedMaintenanceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getSpaceIndex
        ].annualised_maintenance_cost;
      }
      return null;
    },
    specialityPatientEpisodesFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getPatientIndex
        ].patient_episodes;
      }
      return null;
    },
    specialityPatientEpisodesTotalFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getPatientIndex
        ].patient_episodes_total;
      }
      return null;
    },
    specialityFinanceNameFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getFinanceIndex
        ].finance;
      }
      return null;
    },
    specialityFinanceTotalFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getFinanceIndex
        ].finance_total;
      }
      return null;
    },
    areaAreaFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .area;
      }
      return null;
    },
    areaTFSFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .total_floor_space;
      }
      return null;
    },
    areaNBCFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .net_build_cost;
      }
      return null;
    },
    areaFDCFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .full_dev_cost;
      }
      return null;
    },
    areaAFMCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .avg_fm_cost;
      }
      return null;
    },
    areaFMCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getAreaIndex]
          .fm_cost;
      }
      return null;
    },

    carbFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getCarbIndex]
          .carb_foot;
      }
      return null;
    },
    carbVol() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getCarbIndex]
          .carb_vol;
      }
      return null;
    },
    carbEpisodes() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getCarbIndex]
          .carb_episodes;
      }
      return null;
    },
    carbTotVol() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getCarbIndex]
          .carb_tot_vol;
      }
      return null;
    },

  fmFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getFMIndex]
          .fac_man;
      }
      return null;
    },
    fmPCFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getFMIndex]
          .patient_catering;
      }
      return null;
    },
     fmCleaningFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getFMIndex]
          .cleaning;
      }
      return null;
    },
    fmReceptionFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getFMIndex]
          .reception;
      }
      return null;
    },
    fmPorteringFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getFMIndex]
          .portering;
      }
      return null;
    },
    fmSecCarParkFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getFMIndex]
          .sec_car_parking;
      }
      return null;
    },
    fmWasteFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getFMIndex]
          .waste;
      }
      return null;
    },
    fmhvacFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getFMIndex]
          .hvac;
      }
      return null;
    },
    fmUtilitiesFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getFMIndex]
          .utilities;
      }
      return null;
    },
    fmEnergyFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getFMIndex]
          .energy;
      }
      return null;
    },
 fmMEFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getFMIndex]
          .m_e;
      }
      return null;
    },
    fmBuildingEstatesFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getFMIndex]
          .buildings_and_estates;
      }
      return null;
    },
    fmCapAndMinorFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getFMIndex]
          .capital_and_minor;
      }
      return null;
    },
 fmTotalFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getFMIndex]
          .total;
      }
      return null;
    },
     fmManagementFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getFMIndex]
          .management;
      }
      return null;
    },
     fmSupervisorsFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getFMIndex]
          .supervisors;
      }
      return null;
    },
     fmAdministrationFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getFMIndex]
          .administration;
      }
      return null;
    },
   

    gfaFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getGFAIndex]
          .gfa;
      }
      return null;
    },
    specialityFinanceNameFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("finance_total")
        ].finance;
      }
      return null;
    },
    specialityFinanceTotalFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("finance_total")
        ].finance_total;
      }
      return null;
    },
    specialityFinanceTotalCostBase() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        //console.log ("specialityFinanceTotalCostBase :", this.$store.state.prototypedata.specialityData[this.getDataSetIndex("finance_total")].finance_total[0]);
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("finance_total")
        ].finance_total[0];
      }
      return null;
    },
    specialityFinanceBeneficiaryCapitationRate() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("finance_total")
        ].finance_total[4];
      }
      return null;
    },
    specialityFinanceTotalFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.specialityFinanceTotalFilter1)
      );

      return str;
    },

    metricActivityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getDataSetIndex("hsi_benchmark")]
          .activity;
      }
      return null;
    },
    // metricBeneficiaryFilter
    metricBeneficiaryFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getDataSetIndex("hsi_benchmark")]
          .beneficiary;
      }
      return null;
    },
    metricMetricsFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getDataSetIndex("hsi_benchmark")]
          .metrics;
      }
      return null;
    },
    metricHSIBenchmarkFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getDataSetIndex("hsi_benchmark")]
          .hsi_benchmark;
      }
      return null;
    },
    metricInternationalBenchmarkFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.getDataSetIndex("hsi_benchmark")]
          .international_benchmark;
      }
      return null;
    },
   

    //-----------------------------------------------------------------------------------
    // SUMMARY WORKFORCE
    //-----------------------------------------------------------------------------------
    // Grand Summary
    // Summary Workforce Table
    // Workforce Filter
    workforceTableWorkforceFilter() {
      //console.log(
//        "workforceTableWorkforceFilter: ",
  //      this.$store.state.prototypedata.specialityData.length
    //  );
      if (this.$store.state.prototypedata.specialityData.length > 5) {
      //  console.log(
        //  "workforceTableWorkforceFilter: ",
          //this.$store.state.prototypedata.specialityData[
           // this.getDataSetIndex("summary_workforce")
          //]
        //);
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_workforce")
        ].summary_workforce;
      } else {
        let summaryArray = new Array();
        summaryArray.push("No data");
        return summaryArray;
      }
    },
    workforceSummaryTableWorkforceFilter() {
     // console.log("workforceSummaryTableWorkforceFilter");
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        var i = this.getDataSetIndex("cost_per_staff");

        if (i >= 0) {
          return this.$store.state.prototypedata.specialityData[i].workforce;
        }
      }
      return null;
    },
    workforceSummaryTableQuantityFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceSummaryTableQuantityFilter)
      );

      return str;
    },
    workforceSummaryTableQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("cost_per_staff")
        ].workforce_quantity;
      }
      return null;
    },
    workforceSummaryTableCostPerStaffFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("cost_per_staff")
        ].cost_per_staff;
      }
      return null;
    },
    workforceSummaryTableCostPerStaffFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceSummaryTableCostPerStaffFilter)
      );

      return str;
    },
    workforceSummaryTableTotalWorkforceCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("cost_per_staff")
        ].total_workforce_cost;
      }
      return null;
    },
    workforceSummaryTableTotalWorkforceCostFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceSummaryTableTotalWorkforceCostFilter)
      );

      return str;
    },
    specialityPatientEpisodesFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("patient_episodes_total")
        ].patient_episodes;
      }
      return null;
    },
    specialityPatientEpisodesTotalFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("patient_episodes_total")
        ].patient_episodes_total;
      }
      return null;
    },
    specialityPatientEpisodesTotalFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.specialityPatientEpisodesTotalFilter1)
      );

      return str;
    },
    patientEpisodeProductivityFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculatePatientEpisodeProductivityCost(
          this.$store.state.prototypedata.specialityData[
            this.getDataSetIndex("patient_episodes_total")
          ].patient_episodes_total
        );
      }
      return null;
    },
    // Grand Summary
    // Summary Workforce Table
    // Workforce Quantity Filter
    workforceTableQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        var i = 0;
        i = this.getDataSetIndex("summary_workforce");

        if (i >= 0) {
          return this.$store.state.prototypedata.specialityData[i]
            .summary_workforce_quantity;
        }

        return null;
      } else {
        let summaryArray = new Array();
        summaryArray.push("No data");
        return summaryArray;
      }
    },
    summaryWorkforceQuantitySum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.workforceTableQuantityFilter)
      );

      return str;
    },
    assetsFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].assets;
      }
      return null;
    },
    assetsQuantityFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].assets_quantity;
      }
      return null;
    },
    assetsQuantityFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsQuantityFilter1)
      );

      return str;
    },

    assetsCapitalCostPerItemFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].capital_cost_per_item;
      }
      return null;
    },
    assetsCapitalCostPerItemFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsCapitalCostPerItemFilter1)
      );

      return str;
    },
    assetsTotalCapitalCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].total_capital_cost;
      }
      return null;
    },
    assetsTotalCapitalCostFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsTotalCapitalCostFilter1)
      );

      return str;
    },
    assetsLifeCycleFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].life_cycle;
      }
      return null;
    },
    assetsLifeCycleFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsLifeCycleFilter1)
      );

      return str;
    },
    assetsAnnualisedCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].annualised_cost;
      }
      return null;
    },
    assetsAnnualisedCostFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsAnnualisedCostFilter1)
      );

      return str;
    },
    assetsAnnualisedMaintenanceCostFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("annualised_cost")
        ].annualised_maintenance_cost;
      }
      return null;
    },
    assetsAnnualisedMaintenanceCostFilter1Sum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.assetsAnnualisedMaintenanceCostFilter1)
      );

      return str;
    },
    assetsTotalCapitalCostOverallFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateAssetsTotalCapitalCost(
          this.$store.state.prototypedata.specialityData[
            this.getDataSetIndex("annualised_cost")
          ].total_capital_cost
        );
      }
      return null;
    },
    assetsAnnualisedCostOverallFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateAssetsAnnualisedCost(
          this.$store.state.prototypedata.specialityData[
            this.getDataSetIndex("annualised_cost")
          ].annualised_cost
        );
      }
      return null;
    },
    assetsAnnualisedMaintenanceCostOverallFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateAssetsAnnualisedMaintenanceCost(
          this.$store.state.prototypedata.specialityData[
            this.getDataSetIndex("annualised_cost")
          ].annualised_maintenance_cost
        );
      }
      return null;
    },
    // Grand Summary
    // Summary Workforce Table
    // Workforce TotalCost Filter
    summaryWorkforceTotalCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 5) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_workforce")
        ].summary_workforce_total_cost;
      } else {
        let summaryArray = new Array();
        summaryArray.push("No data");
        return summaryArray;
      }
    },
    summaryWorkforceTotalCostSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryWorkforceTotalCostFilter)
      );

      return str;
    },

    //-----------------------------------------------------------------------------------
    // SUMMARY ASSET
    //-----------------------------------------------------------------------------------
    // Grand Summary
    // Summary Asset Table
    // Asset Filter
    summaryAssetFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 6) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_assets")
        ].summary_assets;
      }
      return null;
    },
    // Grand Summary
    // Summary Asset Table
    // Assets Quantity Filter
    summaryAssetQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 6) {
        var i = this.getDataSetIndex("summary_assets");

        if (i >= 0) {
          return this.$store.state.prototypedata.specialityData[i]
            .summary_assets_quantity;
        }
      }
      return null;
    },
    summaryAssetQuantityFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryAssetQuantityFilter)
      );

      return str;
    },
    // Grand Summary
    // Summary Asset Table
    // Asset Total Capital Cost Filter
    summaryAssetTotalCapitalCostFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 6) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_assets")
        ].summary_assets_total_capital_cost;
      }
      return null;
    },
    summaryAssetTotalCapitalCostFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryAssetTotalCapitalCostFilter)
      );

      return str;
    },
    // Grand Summary
    // 
    // 
   
    totalHealthEpisodesPerCapita() {
      if (this.$store.state.prototypedata.specialityData.length > 7) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("workforce_cost_per_capita")
        ].total_health_episodes_per_capita[0];
      }
      return null;
    },

    // Grand Summary
    // Summary Patient Table
    // Patient Episodes Filter
    summaryPatientFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 7) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_patient_episodes")
        ].summary_patient_episodes;
      }
      return null;
    },
    // Grand Summary
    // Summary Patient Table
    // Patient Quantity Filter
    summaryPatientQuantityFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 7) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_patient_episodes")
        ].summary_patient_quantity;
      }
      return null;
    },
    summaryPatientQuantityFilterSum() {
      var str = "";
      str = this.numberWithCommas(
        this.calculateArray(this.summaryPatientQuantityFilter)
      );

      return str;
    },
  },
  methods: {   
       getTileData(tileNumber) {
      //console.log("getTileData", tileNumber);

      let defaultValues = new Array();
      let index = -1;

      let currentvalue = "";

      // TODO: Fix These
      defaultValues.push(this.summaryPatientQuantityFilterSum); // 7,103,369
      defaultValues.push(this.summaryAssetQuantityFilterSum);   // 4,076"
      defaultValues.push(this.summaryWorkforceQuantitySum);    //"14,215.89");
      defaultValues.push(this.specialityFinanceTotalCostBase); //"1,762,693,644"
      defaultValues.push(this.WorkforceCostPerCapita);  // 26,299,026
      defaultValues.push(this.TotalHealthEpisodesPerCapita);  // "42,234,201");
      defaultValues.push(this.summaryWorkforceTotalCostSum); //"588,032,670");
      defaultValues.push(this.specialityFinanceBeneficiaryCapitationRate);  //1,469");

      //for (var i = 0; i<20; i++)
      //{
       // defaultValues.push("0");
      //}


      //this.apiPayload.apiString = "grandsummaryone-collection";
      //this.logData();
      index = this.getDataSetIndex("summary_overview");

      currentvalue = defaultValues[tileNumber];

      if (index >= 0) {
        //console.log("index > 0");
        if (
          tileNumber <=
          this.$store.state.prototypedata.specialityData[index].summary_overview
            .length
        ) {
          //console.log("tileNumber");
          if (
            this.$store.state.prototypedata.specialityData[index]
              .summary_overview[tileNumber] == null ||
            this.$store.state.prototypedata.specialityData[index]
              .summary_overview[tileNumber].length == 0
          ) {
            currentvalue = defaultValues[tileNumber];
          } else {
            currentvalue =
              this.$store.state.prototypedata.specialityData[index]
                .summary_overview[tileNumber];
          }
        }
      }

      return currentvalue;
    },

    getPopulation()
    {
      var i = this.getPopulationIndex;
      //console.log ("stage 1 getPopulation", i);

      if (i > -1)
      {
        if (this.$store.state.prototypedata.specialityData.length > 0) 
        {
            this.population = this.$store.state.prototypedata.specialityData[this.getPopulationIndex].population[0];
            //console.log ("stage 1 getPopulation: ", this.population);
        }
      }
      
      return this.population;
    },
    getDataSetIndex(item) {
      var i = 0;
      var id = -1;

      if (this.$store.state.prototypedata.specialityData.length > 0) {
        for (
          i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          if (item in this.$store.state.prototypedata.specialityData[i]) {
            id = i;
          }
        }
      }

      //console.log("getDataSetIndex", item, id);

      return id;
    },
    logData() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        console.log(
          "logData: length: ",
          this.$store.state.prototypedata.specialityData.length
        );
        for (
          var i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          console.log(
            "item[",
            i,
            "]=",
            this.$store.state.prototypedata.specialityData[i]
          );
        }
      }
    },
    fetchData(speciality) {
      console.log("fetchData: ");
      this.apiPayload.apiString = speciality.apiString;
      this.apiPayload.speciality = "fetchData";

      this.specialityApi = speciality.apiString;

      this.getMongoDBData();
    },
    getMongoDBData() {
      //console.log("called from: ", calledfrom);
      //console.log(this.apiPayload.apiString);
      //console.log(this.apiPayload.speciality);
      //console.log("----");

      //if (this.activeSummary == "workforceCost") {
      //this.fillData("workforceCost");
      //}

      this.$store.dispatch("GetData", this.apiPayload).then((data) => {
        console.log("data", data);
      });
    },
    specialityHeader(header) {
      return header;
    },
    editActiveStageSummary(activeSummary) {
      this.activeSummary = activeSummary;
    },
    fillData(tileValue) {
      //console.log ('fillData:', tileValue);
      this.activeSummary = tileValue;

      //console.log("fillData", tileValue);
      this.datacollection = {
        //labels: ['Nursing', 'Medical','Technicians','AHPs','Management','Administration','Drivers','System HQ'],
        labels: this.getBarGraphLabels(),
        datasets: [
          {
            label: this.getGraphTitle(),
            backgroundColor: "#183BCA",
            data: this.getBarGraphDataValues(),
          },
        ],
      };
    },
    editSpecialityType(specialityTypeName) {
      if (specialityTypeName == "Patient Episodes") {
        this.specialityType = "patientEpisodes";
      }
      if (specialityTypeName == "Finance") {
        this.specialityType = "finance";
      }
      if (specialityTypeName == "Workforce") {
        this.specialityType = "workforce";
      }
      if (specialityTypeName == "Space & Property") {
        this.specialityType = "space";
      }

      if (specialityTypeName == "Tactical Assumptions") {
        this.specialityType = "tactical";
      }

      console.log(
        "editSpecialityType (",
        specialityTypeName,
        ")=",
        this.specialityType
      );
    },
    
    editImprovementType(specialityTypeName) {
      console.log ("editImprovement: ", specialityTypeName);
      if (specialityTypeName == "Diagnostics") {
        this.improvementType = "diagnostics";
      }
      if (specialityTypeName == "Outpatient Clinics") {
        this.improvementType = "OPD";
      }
      if (specialityTypeName == "OR") {
        this.improvementType = "OR";
      }
      if (specialityTypeName == "Emergency Room (ER)") {
        this.improvementType = "ER";
      }

      if (specialityTypeName == "Bed Flow") {
        this.improvementType = "bedflow";
      }

      console.log(
        "editImprovementType (",
        specialityTypeName,
        ")=",
        this.improvementType
      );
    },
    getBarGraphDataValues() {
      var data = new Array();
      var temp;
      var str;

      //console.log("getBarGraphDataValues");

      //console.log("DataValues: this.activeSummary = ", this.activeSummary);

      if (this.activeSummary == "workforceCost") {
        //console.log("begin temp1");
        temp = this.workforceTableQuantityFilter;
        //console.log("end temp1");
      } else if (this.activeSummary == "assets") {
        //console.log("begin temp2");
        temp = this.summaryAssetQuantityFilter;
        //console.log("end temp2");
      } else if (this.activeSummary == "finance") {
        //console.log("begin temp3");
        temp = this.summaryAssetQuantityFilter;
        //console.log("end temp3");
      } else if (this.activeSummary == "patientEpisode") {
        //console.log("begin temp4");
        temp = this.summaryPatientQuantityFilter;
        //console.log("end temp4");
      }

      //console.log("temp", temp);

      if (this.isEmpty(temp) == false || typeof temp !== "undefined") {
        //console.log("temp", temp);
        for (var i = 0; i < temp.length; i++) {
          str = temp[i].replaceAll(",", "");
          data.push(str);
        }
      }

      //console.log("getBarGraphDataValues:", data);

      return data;
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },    
    isNumeric(str) 
    {
        if (typeof str != "string") 
        {
            return false // we only process strings!  
        }
        
        // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        return !isNaN(str) && !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    calculateArray(data) {
      let arrayValuesFloatArray = new Array();
      let str = "";
      let parse = false;

      if (typeof data != "undefined") {
        //tempValue = 0;
        str = "";
        parse = false;

        for (var i = 0; i < data.length; i++) {
          //console.log("data[i] = ", data[i]);
          str = String(data[i]);
          str = str.replaceAll(",", "");
          //str = str.replaceAll(",", ".");

          //str = str.replaceAll(".", ",");

          if (str.includes(".")) {
            parse = true;
          }

          //console.log("str[i] = ", str);

          arrayValuesFloatArray.push(parseFloat(str));

          //console.log("arrayValuesFloatArray[i]=", arrayValuesFloatArray[i]);

          //tempValue = tempValue + str;
        }
      }

      //console.log("tempValue = ", tempValue);

      const total = arrayValuesFloatArray.reduce(function (a, b) {
        return a + b;
      });
      if (parse == true) {
        return total.toFixed(2);
      } else {
        return total;
      }
    },
    getBarGraphLabels() {
      var data = new Array();
      var temp;

      if (this.activeSummary == "workforceCost") {
        temp = this.workforceTableWorkforceFilter;
      } else if (this.activeSummary == "assets") {
        temp = this.summaryAssetFilter;
      } else if (this.activeSummary == "finance") {
        temp = this.specialityFinanceNameFilter1;
      } else if (this.activeSummary == "patientEpisode") {
        temp = this.summaryPatientFilter;
      }

      for (var i = 0; i < temp.length; i++) {
        data.push(temp[i]);
      }

      //console.log("getBarGraphLabels", data);

      //console.log ('temp', temp);
      //console.log ('data', data);

      return data;
    },
    createNewDataTable (id,i, j)
    {
      console.log ("createNewDataTable: (" + String(id) + "," + String (i) + ", " + String (j) + ");");
      //delete this.dataTable;
      //this.dataTable = {};

      if (this.isEmpty(this.dataTable) == false || typeof this.dataTable !== "undefined") 
      {
         console.log ("1");
      } 
      else
      {
        console.log ("2");
      }

      console.log ("this.dataTable.length: ", this.dataTable.length);
      console.log ("this.dataTable[0].length: ", this.dataTable[0].length);
      console.log ("this.dataTable[1].length: ", this.dataTable[1].length);

    

      for (let i_i = 0; i_i<=i; i_i++)
      {
          this.dataTable[i_i] = []
          for (let j_j= 0; j_j<=j; j_j++)
          {
            this.dataTable [i_i][j_j] = "-";
          }
       }    
    },
    createRefNewDataTable (id,i, j)
    {
      console.log ("createNewDataTable: (" + String(id) + "," + String (i) + ", " + String (j) + ");");
      //delete this.dataTable;
      //this.dataTable = {};

      for (let i_i = 0; i_i<=i; i_i++)
      {
          this.dataTable[i_i] = []
          for (let j_j= 0; j_j<=j; j_j++)
          {
            this.dataTable [i_i][j_j] = "-";
          }
       }    
    },
    addTable() {
      console.log ("addTable");

      this.rows = Math.ceil (Math.random() * 20);
      this.cols = Math.ceil (Math.random() * 10);

      console.log ("this.rows: ", String (this.rows));
      console.log ("this.cols: ", String (this.cols));

      this.createNewDataTable (this.dataSets, this.rows, this.cols);

      this.dataSets = this.dataSets + 1;

    },
    displayTable ()
    {
      let j = 0;
      let i = 0;

      console.log ("displayTable");

      console.log ("dataTable", this.dataTable);

      console.log ("dataTable length: ", this.dataTable.length);

        console.log ("this.dataTable[0].length   : ", this.dataTable[0].length);
        console.log ("this.dataTable[0][0].length: ", this.dataTable[0][0].length);
        console.log ("this.dataTable             : ", this.dataTable);
        console.log ('this.dataTable[0]          : ', this.dataTable[0]);
        console.log ('this.dataTable[0][0]       : ', this.dataTable[0][0]);
        console.log ('nnnnnn                     : ', Object.keys(this.dataTable).length);

        console.log ("i =  " + String(Object.keys(this.dataTable).length));
        console.log ("j =  " + String(this.dataTable[0].length));

        i = Object.keys(this.dataTable).length + 200;
        i = Object.keys(this.dataTable).length;

        j =  this.dataTable[0].length + 100;
        j = this.dataTable[0].length;

        console.log ("i =  " + String(i));
        console.log ("j =  " + String(j));

        
        for (let i_i = 0; i_i < i; i_i++)
        {
          for (let j_j = 0; j_j < j; j_j++)
          {
                console.log ("dataTable [" + String(i_i) + "],[" + String (j_j) + "] = " + String (this.dataTable[i_i][j_j]));
          }
        }

    },
    getGraphTitle() {
      var str;

      if (this.activeSummary == "workforceCost") {
        str = "Workforce Quantity";
      } else if (this.activeSummary == "assets") {
        str = "Asset Quantity";
      } else if (this.activeSummary == "finance") {
        str = "Finance Quantity";
      } else if (this.activeSummary == "patientEpisode") {
        str = "Patient Episodes Quantity";
      }
      return str;
    },

    summaryTotalQuantityFilter1() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.calculateTotalQuantity(
          this.$store.state.prototypedata.specialityData[
            this.getDataSetIndex("cost_per_staff")
          ].workforce_quantity
        );
      }
      return null;
    },
    // Summary Overview
    // Controls the information on the boxes within the Stage
    specialityOverviewFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[
          this.getDataSetIndex("summary_overview")
        ].summary_overview;
      }
      return null;
    },
    editStageSummary(stageSummary) {
      console.log ("editStageSummary: ", stageSummary);

      //console.log ("specialityData length: ", this.$store.state.prototypedata.specialityData.length);

      //console.log ("specialityData: ", this.$store.state.prototypedata.specialityData);



      this.stageSummary = stageSummary;

      if (this.stageSummary == "stage") {
        this.apiPayload.apiString = "grandsummaryone-collection";

        console.log("stage: getData");

        this.getMongoDBData();
        this.population = this.getPopulation();
        this.fillData("workforceCost");

        
      }

      if (this.stageSummary == "facman") 
      {
        this.apiPayload.apiString = "grandsummaryone-collection";
        this.getMongoDBData();
      }


      //if (this.stageSummary == "buildingsFM") {
        //this.apiPayload.apiString = "fm_build1_collection";
        //this.getMongoDBData();
      //}

      if (this.stageSummary == "specialities") {
        console.log(
          " this.selectedSpecialityArea.selected",
          this.selectedSpecialityArea
        );

        this.editSpecialityType(this.selectedSpecialityArea);
        this.apiPayload.apiString = this.specialityApi;
        this.getMongoDBData();
      }

      if (this.stageSummary == "opImprov") {
        console.log(
          " this.improvementSpecialityAra.selected",
          this.improvementSpecialityArea
        );

        this.editImprovementType(this.improvementSpecialityArea);
        //this.apiPayload.apiString = this.specialityApi;
        //this.getMongoDBData();
      }
    },
  },
};
</script>
<style>
.chartcontainersummaryone {
  border: 1px solid;
  border-color: #e5e9f2;
  height: 400px;
  display: inline-block;
  width: 350px; /* was 26 rem */
  margin-right: 20px;
  padding-bottom: 5px;
}
.title_text1 {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

/*a:link {
  color: green;
  background-color: transparent;
  text-decoration: none;
}
a:visited {
  color: pink;
  background-color: transparent;
  text-decoration: none;
}
a:hover {
  color: red;
  background-color: transparent;
  text-decoration: underline;
}
a:active {
  color: yellow;
  background-color: transparent;
  text-decoration: underline;
}*/

.scrolltable {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.scrolltable tbody {
  display: table;
  width: 100%;
}


</style>

